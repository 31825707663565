#incident-advanced-search{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1999 !important;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 0px 3px #666;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.close-icon{
    font-size: 20px;
    color: red;
    cursor: pointer;
}
.fieldset{
    border: 2px solid #E6E9ED;
    border-radius: 0px;
    border-color: gray;
    border-width: 1px;
}
legend{
    font-size: small;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}